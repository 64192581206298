import Splide from '@splidejs/splide';

const axios = require('axios');

function setMobileSlideOptions(options) {
    if (window.matchMedia('(min-width: 1024px)').matches) {
        options.drag = false;
    } else {
        options.drag = true;
    }
    return options;
}

// Partners filters
function applyPartnerFilter(filterCheckbox, shown) {
    document.querySelectorAll(`.partner-${filterCheckbox.value}-card`).forEach(function (partnerCard) {
        if (shown) {
            partnerCard.classList.remove('hidden');
        } else {
            partnerCard.classList.add('hidden');
        }
    });
}

document.addEventListener('DOMContentLoaded',function() {
    // Testimonies carousel
    if (document.querySelector('.splide-testimonies')) {
        var splideTestimonies = new Splide('.splide-testimonies', {
            drag: true,
            autoplay: true,
            speed: 1000,
            type: 'loop',
            classes: {
                pagination: 'splide__pagination',
                page: 'splide__pagination__page bg-white mx-2'
            }
        }).mount();

        splideTestimonies.options = setMobileSlideOptions(splideTestimonies.options);
    }

    window.addEventListener('resize', function() {
        if (splideTestimonies) {
            splideTestimonies.options = setMobileSlideOptions(splideTestimonies.options);
        }
    });

    // --- Contact form ---
    var contactForm = document.querySelector('#contact-form');
    if (contactForm) {
        document.querySelectorAll('#contact-form input').forEach(input => {
            ['click', 'keypress', 'change'].forEach(evt => {
                input.addEventListener(evt, function() {
                    if (!contactInitTime) contactInitTime = new Date();
                });
            });
        });

        // HP
        setTimeout(function() {
            document.querySelector('#terms').setAttribute('aria-required', 'false');
            document.querySelector('#terms-label').setAttribute('aria-required', 'false');
        }, 10000);

        var contactFormButton = document.querySelector('#contact-form button');
        var contactInitTime = null;

        contactForm.addEventListener('submit', function (event) {
            event.preventDefault();

            const formAxios = axios.create();
            formAxios.interceptors.request.use(config => {
                contactFormButton.setAttribute('disabled', 'disabled');
                contactFormButton.setAttribute('aria-disabled', true);
                return config;
            });

            var body = new FormData(contactForm);
            body.append('time', new Date() - contactInitTime);

            function updateValidationMessages(data, request) {
                ['firstname', 'lastname', 'email', 'phone', 'subject', 'message'].forEach(id => {
                    var input = contactForm.querySelector('#' + id);
                    var errorSpan = input.nextElementSibling;
                    if (data && id in data) {
                        input.setAttribute('aria-invalid', true);
                        errorSpan.innerHTML = request.response.data[id][0];
                    } else {
                        input.setAttribute('aria-invalid', false);
                        errorSpan.innerHTML = '&nbsp;';
                    }
                });
            }

            formAxios.post(contactForm.getAttribute('action'), body)
                .then(function (request) {
                    updateValidationMessages(null, request);
                    contactFormButton.innerHTML = contactFormButton.dataset.submitMessageSent;
                })
                .catch(function (request) {
                    if(request.response.data) {
                        updateValidationMessages(request.response.data, request);
                        contactFormButton.innerHTML = contactFormButton.dataset.submitMessageInvalid;
                        contactFormButton.removeAttribute('disabled');
                        contactFormButton.setAttribute('aria-disabled', false);
                    } else {
                        contactFormButton.innerHTML = contactFormButton.dataset.submitMessageError;
                    }
                });
        });

        document.querySelectorAll('.contact-me').forEach(function (elem) {
            elem.addEventListener('click', function() {
                var memberSelect = contactForm.querySelector('#contacted_member');

                for (var i = 0; i < memberSelect.length; i++) {
                    if (memberSelect[i].value == elem.dataset.contactMe) {
                        memberSelect[i].selected = true;
                    }
                }
            });
        });

        var partnersFiltersForm = document.querySelector('#partner-filters-form');
        if (partnersFiltersForm) {
            var filtersCheckboxes = partnersFiltersForm.querySelectorAll('.partner-filter');

            partnersFiltersForm.querySelectorAll('[id^="partner-"]').forEach(filterCheckbox => {
                filterCheckbox.addEventListener('change', function() {
                    var showAll = partnersFiltersForm.querySelectorAll('.partner-filter:checked').length == 0;
                    filtersCheckboxes.forEach(function(filterCheckbox) {
                        applyPartnerFilter(filterCheckbox, showAll || filterCheckbox.checked);
                    });
                });
            });
        }
    }
});
